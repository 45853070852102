import {
  AppBar,
  Box,
  FormControlLabel,
  Radio,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";
// @mui
// hooks
import AccountPopover from "./AccountPopover";
import HeaderClock from "./HeaderClock";

import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/Iconify";
import Loader from "../../../components/Loader";
import Logo from "../../../components/Logo";
import { HEADER, NAVBAR } from "../../../config";

import useAuth from "../../../hooks/useAuth";
import useMetaData from "../../../hooks/useMetaData";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import { apiRouter } from "../../../services/apis";
import cssStyles from "../../../utils/cssStyles";
import { fNumber } from "../../../utils/formatNumber";

// config
// components
//

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");
  const { user } = useAuth();

  const [userInfo, isUserInfoLoading, fetchUserInfo] = useMetaData(
    apiRouter.GET_LOGGED_USER_DETAILS,
    {},
    true,
    false
  );
  let userChips = localStorage.getItem("userChips");
  if (!userChips) {
    localStorage.setItem("userChips", user.realChips);
  }
  if (userInfo?.realChips) {
    localStorage.setItem("userChips", userInfo.realChips);
    delete userInfo.realChips;
  }
  userChips = localStorage.getItem("userChips");

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isDesktop && <HeaderClock />}
          {/* <ThemeC olorChanger />
          <ThemeModeSwitch /> */}
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {isUserInfoLoading ? (
              <Loader />
            ) : (
              <>
                <Typography variant="body1" sx={{ color: "text.primary" }}>
                  Wallet Balance:
                  <Typography component="b">
                    {user?.realChips !== null || userInfo?.realChips !== null
                      ? fNumber(userChips||user?.realChips)
                      : "-"}
                  </Typography>
                </Typography>
                <IconButtonAnimate size="small" onClick={() => fetchUserInfo()}>
                  <Iconify icon={"eva:refresh-fill"} />
                </IconButtonAnimate>
              </>
            )}
          </Stack>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

BoxMask.propTypes = {
  value: PropTypes.string,
};

export function BoxMask({ value }) {
  return (
    <FormControlLabel
      label=""
      value={value}
      control={<Radio sx={{ display: "none" }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: "absolute",
      }}
    />
  );
}
