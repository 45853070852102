/* eslint-disable no-sparse-arrays */
export const adjustmentTypeItems = [
  {
    value: "credit",
    label: "Transfer Points",
  },
  {
    value: "debit",
    label: "Adjust Points",
  },
];
export const playerTreatmentItems = [
  {
    value: 1,
    label: "Flag",
  },
  {
    value: 0,
    label: "Unflag",
  },
  
];

export const onlineStatusItems = [
  { value: "all", label: "All", id: "-1" },
  { value: "active", label: "Online", id: "0" },
  { value: "inActive", label: "Offline", id: "1" },
];

export const userStatusItems = [
  { value: "active", label: "Active", id: "0" },
  { value: "inActive", label: "InActive", id: "1" },
  { value: "suspended", label: "Suspended", id: "2" },
];

export const userNewStatusItems = [
  { value: "active", label: "Un-Banned", id: "0" },
  { value: "inActive", label: "Banned", id: "1" },
  { value: "suspended", label: "Suspended", id: "2" },
];

export const statusItems = [
  { value: "all", label: "All", id: "-1" },
  { value: "banned", label: "Banned", id: "0" },
  { value: "unBanned", label: "Un-Banned", id: "1" },
  { value: "suspended", label: "Suspended", id: "2" },
];

export const USER_STATUS = [
  { value: "banned", label: "Ban", id: "0" },
  { value: "unBanned", label: "Un-Ban", id: "1" },
  { value: "suspended", label: "Suspend", id: "2" },
  { value: "unSuspended", label: "Un-Suspend", id: "3" },
];

export const searchByItems = [
  { value: 4, label: "Super Distributor", rank: 4 },
  { value: 5, label: "Distributor", rank: 5 },
  { value: 6, label: "Agent", rank: 6 },
  { value: 1, label: "Player", rank: 1 },
];

export const allSearchByItems = [
  { value: 0, label: "Self", rank: 0 },
  { value: 3, label: "Operator", rank: 3 },
  { value: 4, label: "Super Distributor", rank: 4 },
  { value: 5, label: "Distributor", rank: 5 },
  { value: 6, label: "Agent", rank: 6 },
  { value: 1, label: "Player", rank: 1 },
];

export const roleByItems = [
  { value: 3, label: "Operator", rank: 3 },
  { value: 7, label: "Sub Admin", rank: 7 },
  { value: 4, label: "Super Distributor", rank: 4 },
  { value: 5, label: "Distributor", rank: 5 },
  { value: 6, label: "Agent", rank: 6 },
];

export const reportByItems = [
  { value: "daily", label: "Day Wise", rank: 0 },
  { value: "weekly", label: "Week Wise", rank: 1 },
  { value: "monthly", label: "Month Wise", rank: 2 },
];

export const userColumn = [
  "userId",
  "promoterId",
  "points",
  "onlyForMobile",
  "online",
];

export const marginOnOptions = [
  { value: 0, label: "TN" },
  { value: 1, label: "RV" },
];

export const YesOrNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const onlyForMobilePlayOptions = [
  { value: 1, label: "MB Only" },
  { value: 0, label: "PC Only" },
  { value: 2, label: "Both" },
];

export const agentManagementOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const superDistributors = [
  { value: "abc", label: "ABC" },
  { value: "123", label: "123" },
  { value: "234", label: "234" },
  { value: "345", label: "345" },
];

export const gameNames = [
  // { value: "all", label: "All" },
  { value: 0, label: "Bingo" },
  { value: 1, label: "Lucky 36" },
  { value: 2, label: "Lucky 36 Mini" },
  { value: 3, label: "Tic-Tac-Toe" },
  { value: 4, label: "Lucky 36 Mega" },
  { value: 5, label: "Cricket" },
];

export const timePeriods = [
  { value: "yesterday", label: "Yesterday" },
  { value: "today", label: "Today" },
  { value: "thisWeek", label: "ThisWeek" },
  { value: "lastWeek", label: "Last Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "lastMonth", label: "Last Month" },
];

export const dateRangeItems = [
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Yesterday",
    value: -1,
  },
  {
    label: "This Week",
    value: -6,
  },
  {
    label: "Last Week",
    value: -7,
  },
  {
    label: "This month",
    value: -29,
  },
  {
    label: "Last month",
    value: -30,
  },
];

export const appPermissions = [
  {
    id: 1,
    name: "Account Book",
    types: [
      {
        id: 11,
        name: "Transfer points",
        parentId: 1,
      },
      {
        id: 12,
        name: "Adjust points",
        parentId: 1,
      },
      {
        id: 13,
        name: "Change Password",
        parentId: 1,
      },
    ],
  },
  {
    id: 2,
    name: "Player",
    types: [
      {
        id: 21,
        name: "Player Search",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    name: "Reports",
    types: [
      {
        id: 31,
        name: "Game History",
        parentId: 3,
      },
      {
        id: 32,
        name: "Transaction Reports",
        parentId: 3,
      },
      {
        id: 33,
        name: "Turnover Reports",
        parentId: 3,
      },
      {
        id: 34,
        name: "Game Wise Turnover Reports",
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    name: "Affiliate Management",
    types: [
      {
        id: 41,
        name: "Distributor",
        parentId: 4,
      },
      {
        id: 42,
        name: "Agent",
        parentId: 4,
      },
    ],
  },
];

export const mainNodes = [1, 2, 5, 7, 13];

export const distributorTypes = {
  SUPER: "super",
  NORMAL: "normal",
  AGENT: "agent",
};

export const distributorRestrictions = ["Distributor"];

export const playersColumns = [
  {
    name: "userName",
    label: "Username",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "agent",
    label: "Agent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "distributor",
    label: "Distributor",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "superDistributor",
    label: "Super Distributor",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "points",
    label: "Points",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "onlineStatus",
    label: "Online",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "pcMob",
    label: "PC/MOB",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "lastLogin",
    label: "Last Login",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const superDistributorColumns = [
  {
    name: "supDist",
    label: "SUPER DIST",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "margin",
    label: "Margin",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "dist",
    label: "DIST",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "agent",
    label: "Agent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "players",
    label: "Players",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "points",
    label: "Points",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const distributorColumns = [
  {
    name: "dist",
    label: "DIST",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "margin",
    label: "Margin",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "supDist",
    label: "SUPER DIST",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "agent",
    label: "Agent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "players",
    label: "Players",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "points",
    label: "Points",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const agentColumns = [
  {
    name: "agent",
    label: "Agent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "margin",
    label: "Margin",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "supDist",
    label: "SUPER DIST",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "dist",
    label: "DIST",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "players",
    label: "Players",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "points",
    label: "Points",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const rolePermissions = {
  PLAYER: 1,
  ADMIN: 2,
  OPERATOR: 3,
  SUPER_DISTRIBUTOR: 4,
  DISTRIBUTOR: 5,
  AGENT: 6,
  SUB_ADMIN: 7,
};

export const roleNames = [
  ,
  "Players",
  "Admin",
  "Operators",
  "Super Distributors",
  "Distributors",
  "Agents",
];

export const roleSingleNames = [
  ,
  "Player",
  "Admin",
  "Operator",
  "Super Distributor",
  "Distributor",
  "Agent",
];

export const rolesRankOrders = [
  {
    role: "operator",
    rank: 3,
  },
  {
    role: "superDistributor",
    rank: 4,
  },
  {
    role: "distributor",
    rank: 5,
  },
  {
    role: "agent",
    rank: 6,
  },
];

export const onlyForMobilePlayOptionsForPlayer = [
  { value: 1, label: "Android Only" },
  { value: 0, label: "Desktop Only" },
  { value: 999, label: "Both" },
];

export const gameReportHeadingItems = [
  { id: 0, label: "Bet Position", align: "left" },
  { id: 1, label: "Bet Amount", align: "left" },
  { id: 3, label: "Bet Type", align: "left" },
];

export const userRoles = {
  ADMIN: "admin",
  OPERATOR: "operator",
  SUPER_DISTRIBUTOR: "super distributor",
  DISTRIBUTOR: "distributor",
  AGENT: "agent",
  PLAYER: "player",
};

export const REGEX = {
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/,
};

export const PAYOUT_TYPES = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
];
export const GAME_STATES = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export const USER_STATUS_KEYWORDS = {
  ACTIVE: "active",
  INACTIVE: "inActive",
  SUSPEND: "suspended",
};

export const INACTIVE_USER_RESTRICTED_ROUTES = [2, 3, 12, 4];

export const ROLES_MENUS = {
  OPERATOR: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
  SUPER_DIS: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ],
};
