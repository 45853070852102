// routes
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  accountBook: getIcon("ic_banking"),
  dashboard: getIcon("ic_home"),
  reports: getIcon("ic_reports"),
  affiliate: getIcon("ic_ecommerce"),
  menu: getIcon("ic_kanban"),
  games: getIcon("ic_games"),
};

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.home,
        icon: ICONS.dashboard,
      },
      {
        title: "Account Book",
        path: PATH_DASHBOARD.accountBook.transferPoints,
        icon: ICONS.accountBook,
        children: [
          {
            title: "Transfer Points",
            path: PATH_DASHBOARD.accountBook.transferPoints,
          },
          {
            title: "Adjust Points",
            path: PATH_DASHBOARD.accountBook.adjustPoints,
          },
          {
            title: "Change Password",
            path: PATH_DASHBOARD.accountBook.changePassword,
          },
        ],
      },

      {
        title: "Player",
        path: PATH_DASHBOARD.player.playerSearch,
        icon: ICONS.user,
        children: [
          { title: "Player Search", path: PATH_DASHBOARD.player.playerSearch },
        ],
      },

      {
        title: "Affiliate",
        path: PATH_DASHBOARD.affiliate.superDistributorManagement,
        icon: ICONS.affiliate,
        children: [
          {
            title: "Operator",
            path: PATH_DASHBOARD.affiliate.operatorManagement,
          },
          {
            title: "Super Distributor",
            path: PATH_DASHBOARD.affiliate.superDistributorManagement,
          },
          {
            title: "Distributor",
            path: PATH_DASHBOARD.affiliate.distributorManagement,
          },
          {
            title: "Agent",
            path: PATH_DASHBOARD.affiliate.agentManagement,
          },
          {
            title: "Sub Admins",
            path: PATH_DASHBOARD.affiliate.subAdmins,
          },
          {
            title: "Create Player",
            path: PATH_DASHBOARD.affiliate.createPlayer,
          },
        ],
      },

      {
        title: "Reports",
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.reports,
        children: [
          {
            title: "Game Reports",
            path: PATH_DASHBOARD.reports.gameHistory,
            children: [
              {
                title: "Game History",
                path: PATH_DASHBOARD.reports.gameHistory,
              },
            ],
          },
          {
            title: "Transaction Reports",
            path: PATH_DASHBOARD.reports.pointFile,
            children: [
              {
                title: "Chips File",
                path: PATH_DASHBOARD.reports.pointFile,
              },
            ],
          },
          {
            title: "Turnover Reports",
            path: PATH_DASHBOARD.reports.turnOverReport,
          },
          {
            title: "Game Wise Turnover Reports",
            path: PATH_DASHBOARD.reports.gameWiseTurnOverReport,
          },
        ],
      },
      {
        title: "Menu",
        path: PATH_DASHBOARD.menu.root,
        icon: ICONS.menu,
        children: [
          { title: "Main Menu", path: PATH_DASHBOARD.menu.mainMenu },
          { title: "Role Based Menu", path: PATH_DASHBOARD.menu.roleBaseMenu },
        ],
      },
      {
        title: "Games",
        path: PATH_DASHBOARD.games.root,
        icon: ICONS.games,
        children: [
          { title: "Games Management", path: PATH_DASHBOARD.games.management },
        ],
      },
      {
        title: "playerTreatment",
        path: PATH_DASHBOARD.playerTreatment.root,
        icon: ICONS.flag,
        children: [
          { title: "Player Treatment", path: PATH_DASHBOARD.playerTreatment.setFlag },
        ],
      },
    ],
  },
];

export default navConfig;
