// ----------------------------------------------------------------------

const path = (root, subLink) => `${root}${subLink}`;

const BASE_PATH = "/";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: BASE_PATH,
  login: path(BASE_PATH, "login"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, "/home"),
  },
  accountBook: {
    adjustPoints: path(ROOTS_DASHBOARD, "/accountBook/adjustPoints"),
    transferPoints: path(ROOTS_DASHBOARD, "/accountBook/transferPoints"),
    changePassword: path(ROOTS_DASHBOARD, "/accountBook/changePassword"),
  },
  player: {
    playerSearch: path(ROOTS_DASHBOARD, "/player/playerSearch"),
  },
  affiliate: {
    agentManagement: path(ROOTS_DASHBOARD, "/affiliate/agentManagement"),
    distributorManagement: path(
      ROOTS_DASHBOARD,
      "/affiliate/distributorManagement"
    ),
    superDistributorManagement: path(
      ROOTS_DASHBOARD,
      "/affiliate/superDistributorManagement"
    ),
    subAdmins: path(ROOTS_DASHBOARD, "/affiliate/subAdmins"),
    createPlayer: path(ROOTS_DASHBOARD, "/affiliate/createPlayer"),
    operatorManagement: path(ROOTS_DASHBOARD, "/affiliate/operatorManagement"),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    gameHistory: path(ROOTS_DASHBOARD, "/reports/gameReports/gameHistory"),
    chipsFile: path(ROOTS_DASHBOARD, "/reports/transactionReport/pointFile"),
    turnOverReport: path(ROOTS_DASHBOARD, "/reports/turnOverReport"),
    gameWiseTurnOverReport: path(
      ROOTS_DASHBOARD,
      "/reports/gameWiseTurnOverReport"
    ),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  menu: {
    root: path(ROOTS_DASHBOARD, "/menu/mainMenu"),
    mainMenu: path(ROOTS_DASHBOARD, "/menu/mainMenu"),
    roleBaseMenu: path(ROOTS_DASHBOARD, "/menu/roleBaseMenu"),
  },
  games: {
    root: path(ROOTS_DASHBOARD, "/games/management"),
    management: path(ROOTS_DASHBOARD, "/games/gameManagement"),
  },
  playerTreatment: {
    playerSearch: path(ROOTS_DASHBOARD, "/playerTreatment/setFlag"),
  },
};
