import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
// routes
import { IconButtonAnimate } from "../../../components/animate";
import MenuPopover from "../../../components/MenuPopover";
import MyAvatar from "../../../components/MyAvatar";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import useToaster from "../../../hooks/useToaster";
import { PATH_AUTH } from "../../../routes/paths";
import { axiosPost } from "../../../services";
import { apiRouter } from "../../../services/apis";
// hooks
// components

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: "Home",
//     linkTo: "/",
//   },
//   {
//     label: "Profile",
//     linkTo: PATH_DASHBOARD.user.profile,
//   },
//   {
//     label: "Settings",
//     linkTo: PATH_DASHBOARD.user.account,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const { toaster } = useToaster();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      const sid = localStorage.getItem("accessToken");
      const response = await axiosPost(apiRouter.LOG_OUT, { sid });
      const { status } = response;
      if (status) {
        await logout();
        navigate(PATH_AUTH.login, { replace: true });
        toaster("Logout Successfully", "success");
        if (isMountedRef.current) {
          handleClose();
        }
      } else {
        return toaster("Something went wrong", "error");
      }
    } catch (error) {
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.userName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
