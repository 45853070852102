import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import {
  INACTIVE_USER_RESTRICTED_ROUTES,
  USER_STATUS_KEYWORDS,
} from "../constants/metaData";
import navConfig from "../layouts/dashboard/navbar/NavConfig";
import { convertMenuToItems } from "../utils/helper";

const useNavConfig = () => {
  const [accessibleNavConfig, setAccessibleNavConfig] = useState(navConfig);
  const { menus, user } = useAuth();
  const isInActiveUser = user?.status?.status === USER_STATUS_KEYWORDS.INACTIVE;

  useEffect(() => {
    if (menus && menus.length > 0) {
      const updatedMenus = isInActiveUser
        ? menus?.filter(({ id }) =>
            INACTIVE_USER_RESTRICTED_ROUTES.includes(id) ? false : true
          )
        : menus;
      const newMenus = convertMenuToItems(updatedMenus || []);
      const finalData = [
        {
          subheader: "",
          items: newMenus,
        },
      ];
      setAccessibleNavConfig(finalData);
    }
  }, [menus, isInActiveUser]);

  return { accessibleNavConfig };
};

export default useNavConfig;
