import axiosInstance from "../utils/axios";
import { sessionLogout } from "../utils/helper";

export const axiosPost = async (
  url,
  data,
  contentType = "application/json"
) => {
  let response = {};
  try {
    const result = await axiosInstance.post(url, data, {
      headers: {
        "Content-Type": contentType,
      },
    });
    if (result.status === 200) {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined"
          ? result?.data?.success
          : true;
      response.message = result?.data?.message || null;
    } else if (result.status === 401 || result.status === 403) {
      await sessionLogout();
      return;
    } else {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined"
          ? result?.data?.success
          : false;
      response.message = "something went wrong";
    }
  } catch (e) {
    response.status = false;
    response.message = "something went wrong";
    response.data = e;
    if (e.status === 401 || e.status === 403) await sessionLogout();
  }
  return response;
};

export const axiosGet = async (
  url,
  params = {},
  contentType = "application/json"
) => {
  let response = {};
  try {
    const result = await axiosInstance.get(url, {
      headers: {
        "Content-Type": contentType,
      },
      params,
    });
    if (result.status === 200) {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined" &&
        result?.data?.success !== null
          ? result?.data?.success
          : true;
      response.message = result?.data?.message || null;
    } else if (result.status === 401 || result.status === 403) {
      await sessionLogout();
      return;
    } else {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined"
          ? result?.data?.success
          : false;
      response.message = "something went wrong";
    }
  } catch (e) {
    response.status = false;
    response.message = "something went wrong";
    response.data = e;
    if (e.status === 401 || e.status === 403) await sessionLogout();
  }
  return response;
};

export const axiosPut = async (url, data, contentType = "application/json") => {
  let response = {};
  try {
    const result = await axiosInstance.put(url, data, {
      headers: {
        "Content-Type": contentType,
      },
    });
    if (result.status === 200) {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined"
          ? result?.data?.success
          : true;
      response.message = result?.data?.message || null;
    } else if (result.status === 401 || result.status === 403) {
      await sessionLogout();
      return;
    } else {
      response.data = result.data;
      response.status =
        typeof result?.data?.success !== "undefined"
          ? result?.data?.success
          : false;
      response.message = "something went wrong";
    }
  } catch (e) {
    response.status = false;
    response.message = "something went wrong";
    response.data = e;
    if (e.status === 401 || e.status === 403) await sessionLogout();
  }
  return response;
};

export const axiosDelete = async (
  url,
  params = null,
  contentType = "application/json"
) => {
  let response = {};
  try {
    const result = await axiosInstance.delete(url, {
      headers: {
        "Content-Type": contentType,
      },
      params,
    });
    if (result.status === 200) {
      response.data = result.data;
      response.status = true;
      response.message = null;
    } else if (result.status === 401 || result.status === 403) {
      await sessionLogout();
      return;
    } else {
      response.data = result.data;
      response.status = false;
      response.message = "something went wrong";
    }
  } catch (e) {
    response.status = false;
    response.message = "something went wrong";
    response.data = e;
    if (e.status === 401 || e.status === 403) await sessionLogout();
  }
  return response;
};
